/* global Component */
class registerPage extends Component {

    static name() {
        return "registerPage";
    }

    static componentName() {
        return "registerPage";
    }

    data(){
        let superdata = super.data();
        superdata.alertSuccess = false;
        superdata.referentId = null;
        return superdata;
    }

    getWatch() {
        return {
            'inSystem': function () {
                // eslint-disable-next-line no-console
                console.log('Change ', this.inSystem);
            },
            '$route.query.referent': function (newValue, oldValue) {
                if(newValue)
                    this.referentId = newValue || sessionStorage.getItem("referentId");
            },
            '$route.params.referent': function (newValue, oldValue) {
                if(newValue)
                    this.referentId = newValue || sessionStorage.getItem("referentId");
            }
        };
    }

    registerSuccess(){
        this.alertSuccess = true;
        sessionStorage.removeItem("referentId");
        if (!this.$store.getters.getSetting.ShowRegistrationSuccessMessage) {
            this.$router.push("/");
        }
    }

    clearForm() {
        this.emitEvent('person-reset');
        this.$router.push("login");
        sessionStorage.removeItem("referentId");
    }

    getComputed(){
        return {
            disableRegisterAction: function () {
                return this.$store.getters.getSetting.DisableRegisterAction;
            }
        };
    }

    getMethods() {
        return {
            registerSuccess:this.registerSuccess,
            clearForm:this.clearForm
        };
    }

    mounted() {
        super.mounted();
        return function () {
            if(sessionStorage.getItem("referentId"))
                this.referentId = sessionStorage.getItem("referentId");
            if(this.$route.params.referent)
                this.referentId = this.$route.params.referent;
            if(this.$route.query.referent)
                this.referentId = this.$route.query.referent;
            
            if(this.$route.name == 'CustomThanks' || this.$route.name == 'SignUpThank')
                this.alertSuccess = true;
            this.subscribeEvent('registerSuccess', this.registerSuccess);
        };
    }


    getTemplate() {
        return `<div>
                 <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                        <section class="padding-small">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="container">
                                <div class="row justify-content-center">
                                  <div class="col-lg-8">
                                    <div class="block text-center align-self-center" v-if="disableRegisterAction">
                                        <div class="block">
                                             <div class="block-header">
                                               <h5 class="text-center">{{tr('Register Form')}}</h5>
                                               <div class="text-center">
                                                    <span>{{tr('MUSTCOMPLETEFIELDS')}}</span>
                                               </div>
                                           </div>
                                            <div class="block-body">
                                                <div class="text-center"><i class="fas fa-exclamation-triangle fa-3x mb-3" style="color: #666;"></i></div>
                                                <h5 class="text-center mb-5">{{tr("REGISTERNOTAVAILABLE")}}.</h5>
                                                <router-link to="/" class="btn btn-custom">{{tr("Go to Homepage")}}</router-link>
                                            </div>
                                        </div>
                                   </div>
                                    <div class="block" v-else>
                                        <section v-if="alertSuccess && $store.getters.getSetting.ShowRegistrationSuccessMessage" class="padding-small">
                                            <div class="block-header">
                                                <h5 class="text-center">{{tr('Welcome and thank you for registering with us')}}</h5>
                                            </div>
                                            <div class="block">
                                                <div class="success-register-message text-center mt-5">
                                                    <i class="icon fas fa-check-circle fa-3x mb-3"></i>
                                                    <p class="lead mb-4">{{tr("You have successfully registered")}}.</p>
                                                    <template v-if="$store.getters.getSetting.RegisterNewClientTemplate">
                                                        <h5>{{tr("As a last step, you must validate your email address before starting to use our virtual store. An email has been sent to you with the link to validate it")}}.</h5>
                                                        <hr>
                                                        <h6>{{tr("Remember to check the spam folder of your email when checking the receipt of the validation email. In case you have not received the email, contact us through our contact channels and we will reply as soon as possible")}}.</h6>
                                                    </template>
                                                    <router-link to="/login" class="btn btn-custom mt-4">{{tr("Login")}}</router-link>
                                                </div>
                                            </div>
                                        </section>
                                        <template v-else>
                                            <div class="block-header">
                                                <h5 class="text-center">{{tr('Register Form')}}</h5>
                                            </div>
                                            <div class="block-body register-form">
                                                <div class="text-center">
                                                    <span>{{tr('MUSTCOMPLETEFIELDS')}}</span>
                                                </div>                                                
                                                <hr>
                                                <PersonGenericComponent :confirmPassword="true" :referente="referentId" />
                                                <div class="form-group" >
                                                    <button class="btn btn-custom btn-custom-login mr-3  float-left" @click="clearForm()">{{tr('LoginText')}}</button>
                                                    <button class="btn btn-secondary btn-custom-register float-right"  @click="emitEvent('person-save')">{{tr('Sign Up')}}</button>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </template>
                          <template v-if="$store.getters.getAppState=='onload'">
                              <div class="loading">
                                  <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                              </div>
                          </template>
                        </section>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

registerPage.registerComponent();
